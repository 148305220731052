import { defineStore } from "pinia";

export const useTaskStore = defineStore('tasks',{
    state:()=>{
        return {
            tasks:[],
            todo:[],
            inprogress:[],
            completed:[],
            overdue:[],
            current:{}
        }
    },
    actions:{
        setTasks(tasks){
            this.resetAllArrys()
            this.tasks = tasks
            this.filteredTasks()
        },
        setTask(data){
          this.current = data
          
        },
        addTask(task){
          if(task){
            this.tasks.unshift(task)
            this.filteredTasks()
          }
        },
        removeTask(id){
            let index = this.tasks.findIndex(task=>task.id == id)
            this.tasks.splice(index,1)
            this.filteredTasks()
        },
        updateTaskDetail(id,data){
            let index = this.tasks.findIndex(task=>task.id == id)
            this.tasks[index] = data
            this.filteredTasks()
        },
        addComment(id,comment){
          let index = this.tasks.findIndex(task=>task.id == id)
          this.tasks[index].Task_Comments.unshift(comment)
        },
        isDueSoon(dueDate) {
            const dueDateTime = new Date(dueDate).getTime();
            const currentDateTime = new Date().getTime();
            const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;
            return (dueDateTime - currentDateTime) <= twoDaysInMilliseconds;
        },
        isOverdue(dueDate) {
            const dueDateTime = new Date(dueDate).getTime();
            const currentDateTime = new Date().getTime();
            return dueDateTime < currentDateTime;
          },
        resetAllArrys(){
          this.todo = []
          this.inprogress = [],
          this.completed =[],
          this.overdue = []
        },
        resetTaskArrys(){
          this.tasks = [];
        },
        filteredTasks(){
          this.resetAllArrys()
            const allowedStatuses = new Set(["todo", "inprogress", "completed", "duesoon"]);
            this.tasks.forEach(task => {
              if (!allowedStatuses.has(task.status)) {
                return;
              }
              const isOverdue = this.isOverdue(task.due_on);
              if (task.status === 'todo' && !isOverdue) {
                this.todo.push(task);
              } else if (task.status === 'inprogress' && !isOverdue) {
                this.inprogress.push(task);
              } else if ((task.status === 'todo' || task.status === 'inprogress') && isOverdue) {
                this.overdue.push(task);
              } else if (task.status === 'completed') {
                this.completed.push(task);
              }
            });
        },
        resetTaskStore(){
          this.tasks=[]
          this.todo=[]
          this.inprogress=[]
          this.completed=[]
          this.overdue=[]
          this.current={}
        }
          
    },
    getters:{
       getTasks:(state)=>{
        return state.tasks
       }
    }
})